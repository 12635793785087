<template>
  <div v-if="item && !loading">
    <div class="ml-5">
      <span class="font-weight-bold text-body-1" style="color: black" left> {{item.title}} </span>
    </div>
    <div class="ml-5">
      <span class="font-weight-bold text-body-2" left> {{item.answer_count.length}} respostas</span>
    </div>
    <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
    />
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'

export default {
  name: 'PieChart',
  components: {
    Pie
  },
  props: {
    item: {
      type: Object,
      default: ()=> {}
    },
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 250
    },
    height: {
      type: Number,
      default: 250
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    loading: false,
    backgroundColor: [],
    chartData: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: []
        }
      ]
    },
      chartOptions: {
        plugins: {
          legend: {
            position: 'right',
          },
        },
        responsive: true,
        maintainAspectRatio: false
      }
  }),
  methods: {
    async setItemAnswers(){
      return await this.item.answer_count.map((i)=>{
        let r = Object.entries(i)
        this.chartData.labels.push(r[0][0])
        this.chartData.datasets[0].data.push(r[0][1])
      })
    },
    async setData(){
      this.loading = true
      await this.setItemAnswers()
      this.setBackgroundColors()
    },
    setBackgroundColors(){
      let r = ['250','173','137','64','25','137']
      let g = ['25','22','37','22','39','37']
      let b = ['239','222','245','222','250','245']
      let aux = Math.round(Math.random() * 5)
      let opacity = 1
      this.chartData.datasets[0].data.map((resp,idx)=>{
        this.backgroundColor.push('rgba('+r[aux]+','+g[aux]+','+b[aux]+', '+opacity+')')
        opacity = (opacity-0.10).toFixed(2)
      })
      this.chartData.datasets[0].backgroundColor = this.backgroundColor
      this.loading = false
    }
  },
  computed: {
    // answers_number(){
    //   return this.item.answer_count.length
    // }
  },
  mounted () {
    this.setData()
  },
}
</script>